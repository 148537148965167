.link,
.link > a {
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: max(calc(14px / 2), calc((5.8vw * 14) / 100)) !important;
  /* line-height: max(calc(91px / 2), calc((5.8vw * 91) / 100)) !important; */
  margin-inline: 15px;
}

.footerLink,
.footerLink > div,
.footerLink > div > span {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  /* font-size: 32px; */

  font-size: max(calc(28px / 2), calc((5.8vw * 28) / 100)) !important;

  display: flex;
  align-items: center;

  color: #ffffff;
  justify-content: flex-start !important;
}

.footerInput {
  width: max(calc(473px / 2), calc((5.8vw * 473) / 100));
  height: max(calc(64px / 2), calc((5.8vw * 64) / 100));

  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: max(calc(85px / 2), calc((5.8vw * 85) / 100));
  padding: 20px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(18px / 2), calc((5.8vw * 18) / 100));
  line-height: max(calc(33px / 2), calc((5.8vw * 33) / 100));

  display: flex;
  align-items: center;
  /* text-transform: capitalize; */

  color: #303947;
}

.subscribeBtn {
  width: max(calc(151px / 2), calc((5.8vw * 151) / 100)) !important;
  height: max(calc(53px / 2), calc((5.8vw * 53) / 100)) !important;

  background: #ff774c !important;
  border-radius: max(calc(50px / 2), calc((5.8vw * 50) / 100)) !important;

  position: absolute !important;
  right: max(calc(10px / 2), calc((5.8vw * 10) / 100)) !important;

  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: max(calc(18px / 2), calc((5.8vw * 18) / 100)) !important;
  line-height: max(calc(44px / 2), calc((5.8vw * 44) / 100)) !important;

  color: #ffffff;
}

.footerText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: max(calc(400px / 2), calc((5.8vw * 400) / 100));
  font-size: max(calc(14px / 2), calc((5.8vw * 14) / 100));
  line-height: max(calc(26px / 2), calc((5.8vw * 26) / 100));
  /* or 186% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  padding: 25px;
  max-width: 550px;
}

.footerTitle {
  font-family: "Piazzolla" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: max(calc(50px / 2), calc((5.8vw * 50) / 100)) !important;
  line-height: max(calc(103px / 2), calc((5.8vw * 103) / 100)) !important;

  text-align: center;

  color: #ffffff;
}

.contactBtn {
  width: max(calc(185px / 1.8), calc((5.8vw * 185) / 100));
  height: max(calc(58px / 1.8), calc((5.8vw * 58) / 100));
  border: 3px solid #000000;
  border-radius: max(calc(40px / 1.8), calc((5.8vw * 40) / 100));
  color: #000000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  font-size: max(calc(16px / 1.8), calc((5.8vw * 16) / 100));
  line-height: max(calc(24px / 1.8), calc((5.8vw * 24) / 100));
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  margin: 0px !important;
  transition: all 0.3s;
}

.contactBtn:hover {
  background-color: #ff774c;
  color: white;
  border: 3px solid #ff774c;
  transition: all 0.3s;
}
