@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&family=Mukta:wght@200;300;400;500;600;700;800&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mukta:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Piazzolla:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Content:wght@400;700&display=swap");
body {
  margin: 0;
  overflow-x: hidden;
}

.big-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  /* font-size: 96px; */
  font-size: max(calc(96px / 1.8), calc((5.8vw * 96) / 100));

  display: flex;
  align-items: center;

  background: linear-gradient(96.21deg, #6bc19a 2.17%, #325ca8 99.18%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

.text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  /* font-size: 24px; */
  font-size: max(calc(24px / 1.8), calc((5.8vw * 24) / 100));

  color: #000000;
}

main {
  /* margin-top: 150px; */
}

.two-column-div {
  column-count: 2;
}

ul {
  list-style-type: none;
  text-align: left;
  padding: 0;
}

.loader {
  border: 5px solid #dbdbdb;
  border-top: 5px solid #ff774c;
  border-radius: 50%;
  width: max(calc(50px / 2), calc((5.8vw * 50) / 100)) !important;
  height: max(calc(50px / 2), calc((5.8vw * 50) / 100)) !important;
  animation: spin 2s linear infinite;

  /* position: absolute;
  right: 0;
  top: 0;
  bottom: 0; */
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  /* left: 0; */
  right: 10px;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dropbtn {
  background-color: transparent;
  text-decoration: none;
  padding: 16px;
  font-size: 16px;
  border: none;

  font-family: "Lato" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  /* font-size: 20px; */
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  /* background-color: #3e8e41; */
}

.arrow {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  margin-left: 10px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.rmdp-wrapper,
.rmdp-wrapper div {
  width: 100%;

  box-shadow: none !important;
}

.rmdp-wrapper {
  min-width: 375px;
}

.rmdp-day {
  width: 34px !important;
  pointer-events: none;
}

.rmdp-selected > span {
  background: #9387a7 !important;
  color: #000000 !important;
  box-shadow: none !important;
  /* mix-blend-mode: multiply; */
}

.spinner {
  height: 30px;
  width: 30px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgb(251, 190, 16, 0.15);
  border-right: 6px solid rgb(251, 190, 16, 0.15);
  border-bottom: 6px solid rgb(251, 190, 16, 0.15);
  border-top: 6px solid rgb(251, 190, 16, 0.8);
  border-radius: 100%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.center-aligned-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}
