.homeHeroImg {
  transform: translateY(0px);
}

.homeHeroText {
  max-width: max(calc(650px / 2), calc((5.8vw * 650) / 100));
}

.homeHeroText > h1,
.homeHeroText > h2,
.homeHeroText > h3 {
  font-family: "Piazzolla";
  font-style: normal;
  font-weight: 600;
  font-size: max(calc(90px / 2), calc((5.8vw * 90) / 100));
  line-height: max(calc(120px / 2), calc((5.8vw * 120) / 100));

  color: #000000;
}

.homeHeroText > h1 > b,
.homeHeroText > h2 > b,
.homeHeroText > h3 > b,
.homeHeroText > h1 > strong,
.homeHeroText > h2 > strong,
.homeHeroText > h3 > strong {
  background-color: #ff774c;
  color: white;
  font-weight: 600;
  padding: 0px 15px 0px 15px;
}

.homeHeroText > p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: max(calc(18px / 2), calc((5.8vw * 18) / 100));
  line-height: max(calc(31px / 2), calc((5.8vw * 31) / 100));

  color: #1b1b1b;
}

.homeHeroBtn {
  width: max(calc(194px / 2), calc((5.8vw * 194) / 100));
  height: max(calc(58px / 2), calc((5.8vw * 58) / 100));
  border: none;
  background: #ff774c;
  border-radius: max(calc(40px / 2), calc((5.8vw * 40) / 100));

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: max(calc(16px / 2), calc((5.8vw * 16) / 100));
  line-height: max(calc(24px / 2), calc((5.8vw * 24) / 100));
  text-decoration: none;
  display: flex;
  align-items: center;
  text-align: center;
  align-content: center;
  justify-content: center;
  text-transform: uppercase;
  color: #ffffff;
}

.homeHeroBtn:hover {
  color: black;
}

.homeServices {
  text-align: center;
  margin-top: 50px;
}

.homeServices > h1 {
  font-family: "Piazzolla";
  font-style: normal;
  font-weight: 600;
  font-size: max(calc(50px / 2), calc((5.8vw * 50) / 100));
  line-height: max(calc(45px / 2), calc((5.8vw * 45) / 100));
  /* identical to box height, or 206% */

  text-align: center;
  position: relative;
  color: #000000;
  /* margin: 0px; */
}

.homeServices > h1::after {
  content: "";
  /* position: absolute; */
  background: url(../assets/images/underline.svg);
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  display: block;
  width: max(calc(300px / 2), calc((5.8vw * 300) / 100));
  height: max(calc(8px / 2), calc((5.8vw * 8) / 100));
  margin: auto;
}

.homeServices > p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: max(calc(18px / 2), calc((5.8vw * 18) / 100));
  line-height: max(calc(30px / 2), calc((5.8vw * 30) / 100));
  /* or 172% */

  text-align: center;

  color: #1b1b1b;
  max-width: max(calc(729px / 2), calc((5.8vw * 729) / 100));
  text-align: center;
  margin: auto;
  margin-top: 22px;
}

.slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  text-decoration: none;
}

.slide > img {
  cursor: pointer;
  margin: auto;
  width: max(calc(450px / 2), calc((5.8vw * 450) / 100));
  height: max(calc(455px / 2), calc((5.8vw * 455) / 100));

  border-radius: 20px;
}

.bigSlide > img {
  width: max(calc(596px / 2.5), calc((5.8vw * 596) / 100));
  height: max(calc(572px / 2.5), calc((5.8vw * 572) / 100));
  object-fit: cover;
}

.slide > h1 {
  font-family: "Piazzolla";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(28px / 2), calc((5.8vw * 28) / 100));
  line-height: max(calc(32px / 2), calc((5.8vw * 32) / 100));

  text-align: center;
  /* margin-top: 28px; */
  color: #000000;
}

.slide > p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: max(calc(20px / 2), calc((5.8vw * 20) / 100));
  line-height: max(calc(21px / 2), calc((5.8vw * 21) / 100));
  text-align: center;
  max-width: max(calc(450px / 2), calc((5.8vw * 450) / 100));
  margin: auto;
  color: #777777;
  margin-top: 15px;
}

.bigSlide > h1 {
  max-width: max(calc(600px / 2), calc((5.8vw * 600) / 100));
  margin-left: auto;
  margin-right: auto;
}

.bigSlide > p {
  max-width: max(calc(600px / 2), calc((5.8vw * 600) / 100));
}

.arrowLeft,
.arrowRight {
  position: absolute;
  top: 30%;
  cursor: pointer;
  z-index: 100;
  opacity: 0.5;
}

.arrowLeft {
  left: max(calc(50px / 2), calc((5.8vw * 50) / 100));
}

.arrowRight {
  right: max(calc(50px / 2), calc((5.8vw * 50) / 100));
}

.arrowLeft:hover,
.arrowRight:hover {
  opacity: 1;
}

.homeAboutus {
  text-align: center;
  margin-top: 100px;
  background-color: #7157ba;
  padding-bottom: 50px;
  margin-bottom: 100px;
}

.homeAboutus > h1 {
  font-family: "Piazzolla";
  font-style: normal;
  font-weight: 600;
  font-size: max(calc(50px / 2), calc((5.8vw * 50) / 100));
  line-height: max(calc(45px / 2), calc((5.8vw * 45) / 100));
  /* identical to box height, or 206% */

  padding-top: 66px;
  text-align: center;
  position: relative;
  color: #ffffff;
  /* margin: 0px; */
}

.homeAboutus > h1::after {
  content: "";
  /* position: absolute; */
  background: url(../assets/images/underlinee.svg);
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  display: block;
  width: max(calc(300px / 2), calc((5.8vw * 300) / 100));
  height: max(calc(8px / 2), calc((5.8vw * 8) / 100));
  margin: auto;
}

.homeAboutus > p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: max(calc(18px / 2), calc((5.8vw * 18) / 100));
  line-height: max(calc(30px / 2), calc((5.8vw * 30) / 100));
  /* or 172% */

  text-align: center;

  color: #ffffff;
  max-width: max(calc(729px / 2), calc((5.8vw * 729) / 100));
  text-align: center;
  margin: auto;
  margin-top: 22px;
}

.homeAboutSection {
  background: #7f65c6;
  border-radius: 30px;
  margin-inline: max(calc(185px / 2), calc((5.8vw * 185) / 100));
  margin-block: max(calc(80px / 2), calc((5.8vw * 80) / 100));
  padding: max(calc(75px / 2), calc((5.8vw * 75) / 100));
}

.welcomeBlock,
.ourTeamBlock,
.brksBlock {
  background: #ffc200;
  border-radius: 50px;

  width: max(calc(500px / 1.7), calc((5.8vw * 500) / 100));
  height: max(calc(260px / 1.7), calc((5.8vw * 260) / 100));
  position: relative;
}

.welcomeBlock > h1,
.ourTeamBlock > h1,
.brksBlock > h1 {
  font-family: "Piazzolla";
  font-style: normal;
  font-weight: 700;
  margin: 0;
  font-size: max(calc(26px / 2), calc((5.8vw * 26) / 100));
  line-height: max(calc(103px / 2), calc((5.8vw * 103) / 100));
}

.welcomeBlock > img {
  width: max(calc(321.17px / 2), calc((5.8vw * 321.17) / 100));
  /* height: max(calc(106.23px / 2), calc((5.8vw * 106.23) / 100)); */
  object-fit: contain;
}

.ourTeamText,
.welcomeText,
.brksText {
  width: max(calc(500px / 2), calc((5.8vw * 500) / 100));
  height: max(calc(250px / 2), calc((5.8vw * 250) / 100));
  /* display: flex; */
  text-align: center;
  align-items: center;
}

.welcomeText > p,
.ourTeamText > p,
.brksText > p {
  font-family: "Montserrat";
  font-style: normal;

  font-size: max(calc(14px / 2), calc((5.8vw * 14) / 100));
  line-height: max(calc(26px / 2), calc((5.8vw * 26) / 100));

  text-align: left;
  color: #ffffff;
}

.ourTeamBlock > img {
  width: max(calc(500px / 2), calc((5.8vw * 500) / 100));
  /* height: max(calc(106.23px / 2), calc((5.8vw * 106.23) / 100)); */
  object-fit: contain;

  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.brksBlock > img {
  width: max(calc(350px / 2), calc((5.8vw * 350) / 100));
  /* height: max(calc(106.23px / 2), calc((5.8vw * 106.23) / 100)); */
  object-fit: contain;

  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: max(calc(-30px / 2), calc((5.8vw * -30) / 100));
  text-align: center;
}

.homeCaseStudy {
  text-align: center;
  margin: 30px auto 0px auto;
}

.homeCaseStudy > h1 {
  font-family: "Piazzolla";
  font-style: normal;
  font-weight: 600;
  font-size: max(calc(50px / 2), calc((5.8vw * 50) / 100));
  line-height: max(calc(45px / 2), calc((5.8vw * 45) / 100));
  /* identical to box height, or 206% */

  text-align: center;
  position: relative;
  color: #000000;
  margin: 20px;
}

.homeCaseStudy > h1::after {
  content: "";
  /* position: absolute; */
  background: url(../assets/images/underline.svg);
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  display: block;
  width: max(calc(300px / 2), calc((5.8vw * 300) / 100));
  height: max(calc(8px / 2), calc((5.8vw * 8) / 100));
  margin: auto;
}

.homeCaseStudy > p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: max(calc(18px / 2), calc((5.8vw * 18) / 100));
  line-height: max(calc(30px / 2), calc((5.8vw * 30) / 100));
  /* or 172% */

  text-align: center;

  color: #1b1b1b;
  max-width: max(calc(729px / 2), calc((5.8vw * 729) / 100));
  text-align: center;
  margin: auto;
  margin-top: 22px;
}

.steam {
  position: relative;
}

.steam > div:nth-child(1) {
  background-color: #00d2c7;
}
.steam > div:nth-child(1) > h1 {
  width: max(calc(943px / 2), calc((5.8vw * 943) / 100));
  height: max(calc(126px / 2), calc((5.8vw * 126) / 100));

  font-family: "Piazzolla";
  font-style: normal;
  font-weight: 600;
  font-size: max(calc(50px / 2), calc((5.8vw * 50) / 100));
  line-height: max(calc(63px / 2), calc((5.8vw * 63) / 100));
  margin: auto;
  margin-bottom: 100px;
  text-align: center;

  color: #000000;
}
.steam > div:nth-child(2) {
  background-color: #e9436b;
}
.steam > div:nth-child(3) {
  background-color: #8d73d8;
}

.steam > div {
  background-image: url(../assets/images/mask.png);
  padding: max(calc(100px / 2), calc((5.8vw * 100) / 100))
    max(calc(200px / 2), calc((5.8vw * 200) / 100))
    max(calc(100px / 2), calc((5.8vw * 100) / 100))
    max(calc(200px / 2), calc((5.8vw * 200) / 100));
}

.steamTextBlack,
.steamTextWhite,
.steamTextBlack > p,
.steamTextWhite > p {
  width: max(calc(612px / 2), calc((5.8vw * 612) / 100));
  height: max(calc(193px / 2), calc((5.8vw * 193) / 100));

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(20px / 2), calc((5.8vw * 20) / 100));
  line-height: max(calc(36px / 2), calc((5.8vw * 36) / 100));
  /* or 180% */
}

.steamTextBlack,
.steamTextBlack > p {
  color: #000000;
}
.steamTextWhite {
  color: #ffffff;
}

.steam > span {
  position: absolute;
  width: max(calc(1200px / 2), calc((5.8vw * 1200) / 100));
  height: max(calc(169px / 2), calc((5.8vw * 169) / 100));
  /* left: 1393px;
  top: 5795px; */
  /* right: max(calc(-950px / 2), calc((5.8vw * -950) / 100)); */
  top: 15%;
  right: max(calc(140px / 2), calc((5.8vw * 140) / 100));

  font-family: 800px max(calc(120px / 2), calc((5.8vw * 120) / 100)) Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: max(calc(120px / 2), calc((5.8vw * 120) / 100));
  line-height: max(calc(102px / 2), calc((5.8vw * 102) / 100));
  /* or 85% */

  text-align: center;

  opacity: 0.7;
  /* border: 1px solid #ffffff; */
  transform: rotate(-90deg);
  transform-origin: right;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px white;
}

.homeKeyBenefits {
  text-align: center;
  margin-top: 100px;
}

.homeKeyBenefits > h1 {
  font-family: "Piazzolla";
  font-style: normal;
  font-weight: 600;
  font-size: max(calc(50px / 2), calc((5.8vw * 50) / 100));
  line-height: max(calc(45px / 2), calc((5.8vw * 45) / 100));
  /* identical to box height, or 206% */

  text-align: center;
  position: relative;
  color: #000000;
  /* margin: 0px; */
}

.homeKeyBenefits > h1::after {
  content: "";
  /* position: absolute; */
  background: url(../assets/images/underline.svg);
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  display: block;
  width: max(calc(300px / 2), calc((5.8vw * 300) / 100));
  height: max(calc(8px / 2), calc((5.8vw * 8) / 100));
  margin: auto;
}

.homeKeyBenefits > p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: max(calc(18px / 2), calc((5.8vw * 18) / 100));
  line-height: max(calc(30px / 2), calc((5.8vw * 30) / 100));
  /* or 172% */

  text-align: center;

  color: #1b1b1b;
  max-width: max(calc(729px / 2), calc((5.8vw * 729) / 100));
  text-align: center;
  margin: auto;
  margin-top: 22px;
}

.benefits {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
}

.benefits > div {
  width: max(calc(394px / 2), calc((5.8vw * 394) / 100));
  height: max(calc(377px / 2), calc((5.8vw * 377) / 100));

  border-radius: 30px;
  margin: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: max(calc(50px / 2), calc((5.8vw * 50) / 100));
}

.benefits > div > label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: max(calc(30px / 2), calc((5.8vw * 30) / 100));
  line-height: max(calc(50px / 2), calc((5.8vw * 50) / 100));
  /* identical to box height, or 343% */

  text-align: center;

  color: #ffffff;
}

.benefits > div > img {
  max-width: max(calc(120px / 2), calc((5.8vw * 120) / 100));
}

.benefits > div:nth-child(1) {
  background: #8c529d;
}
.benefits > div:nth-child(2) {
  background: #01aff5;
}
.benefits > div:nth-child(3) {
  background: #f44c1c;
}

.homePricingPlan {
  text-align: center;
  margin-top: 100px;
  background-color: #ffc200;
  padding: max(calc(100px / 2), calc((5.8vw * 100) / 100));
}

.homePricingPlan > h1 {
  font-family: "Piazzolla";
  font-style: normal;
  font-weight: 600;
  font-size: max(calc(50px / 2), calc((5.8vw * 50) / 100));
  line-height: max(calc(45px / 2), calc((5.8vw * 45) / 100));
  /* identical to box height, or 206% */

  text-align: center;
  position: relative;
  color: #000000;
  /* margin: 0px; */
}

.homePricingPlan > h1::after {
  content: "";
  /* position: absolute; */
  background: url(../assets/images/underline.svg);
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  display: block;
  width: max(calc(300px / 2), calc((5.8vw * 300) / 100));
  height: max(calc(8px / 2), calc((5.8vw * 8) / 100));
  margin: auto;
}

.homePricingPlan > p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: max(calc(18px / 2), calc((5.8vw * 18) / 100));
  line-height: max(calc(30px / 2), calc((5.8vw * 30) / 100));
  /* or 172% */

  text-align: center;

  color: #1b1b1b;
  max-width: max(calc(1200px / 2), calc((5.8vw * 1200) / 100));
  text-align: center;
  margin: auto;
  margin-top: 22px;
}

.plans {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
  border-radius: 20px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 24px 74px rgba(0, 0, 0, 0.04);

  width: fit-content;
  margin-inline: auto;
}

.plans > div {
  width: max(calc(394px / 2), calc((5.8vw * 394) / 100));
  height: max(calc(377px / 2), calc((5.8vw * 377) / 100));

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 20px 30px;
  max-width: 391px;
}

.plans > div:nth-child(1),
.plans > div:nth-child(2) {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.plans > div > h1 {
  /* height: max(calc(50px / 2), calc((5.8vw * 50) / 100)); */

  font-family: "Piazzolla";
  font-style: normal;
  font-weight: 600;
  font-size: max(calc(32px / 2), calc((5.8vw * 32) / 100));
  /* line-height: max(calc(103px / 2), calc((5.8vw * 103) / 100)); */
  margin: 0px;
  padding: 0px;

  color: #000000;
}

.plans > div > h2 {
  /* height: max(calc(50px / 2), calc((5.8vw * 50) / 100)); */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: max(calc(15px / 2), calc((5.8vw * 15) / 100));

  color: #1b1b1b;
}

.plans > div > h3 {
  /* height: max(calc(50px / 2), calc((5.8vw * 50) / 100)); */

  font-family: "Piazzolla";
  font-style: normal;
  font-weight: 600;
  font-size: max(calc(32px / 2), calc((5.8vw * 32) / 100));

  color: #ff774c;
}
.plans > div > ul {
  /* height: max(calc(50px / 2), calc((5.8vw * 50) / 100)); */
}

.plans > div > ul > li {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: max(calc(15px / 2), calc((5.8vw * 15) / 100));

  color: #1b1b1b;
  position: relative;
}

.plans > div > ul > li::before {
  content: "";
  background: url(../assets/icons/check.png);
  display: block;
  width: max(calc(12px / 2), calc((5.8vw * 15) / 100));
  height: max(calc(12px / 2), calc((5.8vw * 15) / 100));
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: max(calc(-50px / 2), calc((5.8vw * -50) / 100));
  top: max(calc(5px / 2), calc((5.8vw * 5) / 100));
}

.plans > div > span {
  /* height: max(calc(50px / 2), calc((5.8vw * 50) / 100)); */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: max(calc(11px / 2), calc((5.8vw * 11) / 100));
  line-height: max(calc(31px / 2), calc((5.8vw * 31) / 100));

  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;

  color: #000000;
}

.planBtn {
  text-decoration: none;
  width: max(calc(213px / 2), calc((5.8vw * 213) / 100));
  /* height: (calc(58px / 2), calc((5.8vw * 58) / 100)); */
  height: 5vh;

  background: #ff774c;
  border-radius: max(calc(40px / 2), calc((5.8vw * 40) / 100));

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: max(calc(16px / 2), calc((5.8vw * 16) / 100));
  line-height: max(calc(24px / 2), calc((5.8vw * 24) / 100));

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
  border: unset;
  color: #ffffff;
  cursor: pointer;
}
.planBtn:hover {
  color: #000000;
}

.servicePageTitle > h1 {
  font-family: "Piazzolla";
  font-style: normal;
  font-weight: 600;
  font-size: max(calc(60px / 2), calc((5.8vw * 60) / 100)); /* Increased size */
  line-height: max(calc(63px / 2), calc((5.8vw * 63) / 100));

  text-align: center;

  color: #201e1e;
}


.servicePageTitle {
  /* background: rgba(115, 251, 211, 0.6); */
  display: flex;
  flex-direction: column;
  align-items: space-between;
  padding: max(calc(60px / 2), calc((5.8vw * 60) / 100));
}
.servicePageTitle > h1 {
  margin-left: max(calc(60px / 2), calc((5.8vw * 60) / 100));
}
.servicePageTitle > h1 > span {
  width: max(calc(94px / 2), calc((5.8vw * 94) / 100));
  height: max(calc(108px / 2), calc((5.8vw * 108) / 100));

  font-family: "Piazzolla";
  font-style: italic;
  font-weight: 900;
  font-size: max(calc(128px / 2), calc((5.8vw * 128) / 100));
  line-height: max(calc(63px / 2), calc((5.8vw * 63) / 100));

  text-align: center;

  color: #1d1c1ccc;
}

.servicePageTitleImages {
  margin-top: max(calc(50px / 2), calc((5.8vw * 50) / 100));
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px; /* Gap between cards */
}

/* Responsive layout for different screen sizes */
@media (max-width: 480px) {
  .servicePageTitleImages > div {
    flex: 1 1 100%; /* 1 card per row on small screens */
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .servicePageTitleImages > div {
    flex: 1 1 calc(50% - 20px); /* 2 cards per row on medium screens */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .servicePageTitleImages > div {
    flex: 1 1 calc(33.33% - 20px); /* 3 cards per row on large screens */
  }
}

@media (min-width: 1025px) {
  .servicePageTitleImages > div {
    flex: 1 1 calc(25% - 20px); /* 5 cards per row on big screens */
  }
}

.servicePageTitleImages > div {
  display: flex; /* Flex layout for internal content */
  flex-direction: column; /* Stack contents vertically */
  align-items: center; /* Center align contents */
  justify-content: center; /* Center contents vertically */
}

.servicePageTitleImages > div > img {
  height: 100%; /* Cover entire card height */
  width: 100%; /* Cover entire card width */
  object-fit: cover; /* Ensure images cover the area */
  border-radius: 15px; /* Rounded corners for images */
  padding: 10px; /* Space between image and card border */
  box-sizing: border-box; /* Include padding in width/height calculations */
}

.servicePageTitleImages > div > h1 {
  margin: 10px 0; /* Space around the title */
  font-size: max(calc(28px / 2), calc((5.8vw * 28) / 100));
  font-family: "Piazzolla";
  font-style: italic;
  color: white; /* Ensure text is visible against the navy background */
  text-align: center; /* Center align text */
}


.servicePagePoints {
  /* padding: max(calc(60px / 2), calc((5.8vw * 60) / 100));
  background-color: rgba(74, 143, 231, 0.6); */
  background: rgba(74, 143, 231, 0.6);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: max(calc(60px / 2), calc((5.8vw * 60) / 100));
}
.servicePagePoints > ul {
  margin-block: max(calc(50px / 2), calc((5.8vw * 50) / 100));
  margin-inline: auto;
  padding-top: max(calc(100px / 2), calc((5.8vw * 100) / 100));
  padding-left: max(calc(20px / 2), calc((5.8vw * 20) / 100));
  padding-right: max(calc(100px / 2), calc((5.8vw * 100) / 100));
  background: #ffc200;
  border-radius: 50px;
  position: relative;
  /* max-width: 1233px; */
}

.servicePagePoints > ul > span {
  position: absolute;
}

.servicePagePoints > ul > span > img {
  width: max(calc(115px / 2), calc((5.8vw * 115) / 100));
  height: max(calc(147px / 2), calc((5.8vw * 147) / 100));
}

.servicePagePoints > ul:nth-child(1) > span {
  bottom: max(calc(50px / 2), calc((5.8vw * 50) / 100));
  left: max(calc(-100px / 2), calc((5.8vw * -100) / 100));
}

.servicePagePoints > ul:nth-child(2) > span {
  top: max(calc(120px / 2), calc((5.8vw * 120) / 100));
  left: max(calc(-60px / 2), calc((5.8vw * -60) / 100));
}

.servicePagePoints > ul > li {
  width: max(calc(500px / 2), calc((5.8vw * 1118) / 100));
  height: max(calc(400px / 2), calc((5.8vw * 118) / 100));

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(32px / 2), calc((5.8vw * 32) / 100));
  line-height: max(calc(45px / 2), calc((5.8vw * 36) / 100));
  color: white;

  text-align: center;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.servicePagePoints > ul > li > span {
  color: #367cff;
}

.servicePagePoints > ul > li > span::before {
  content: "• ";
}

.servicePageMeth {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  padding: max(calc(60px / 2), calc((5.8vw * 60) / 100));
}
.servicePageMeth > h1 {
  font-family: "Piazzolla";
  font-style: normal;
  font-weight: 600;
  font-size: max(calc(60px / 2), calc((5.8vw * 60) / 100)); /* Increased size */
  line-height: max(calc(63px / 2), calc((5.8vw * 63) / 100));

  text-align: center;

  color: #201e1e;
}
.servicePageMeth > h1 > span {
  width: max(calc(94px / 2), calc((5.8vw * 94) / 100));
  height: max(calc(108px / 2), calc((5.8vw * 108) / 100));

  font-family: "Piazzolla";
  font-style: italic;
  font-weight: 900;
  font-size: max(calc(128px / 2), calc((5.8vw * 128) / 100));
  line-height: max(calc(63px / 2), calc((5.8vw * 63) / 100));

  text-align: center;

  color: #ffffff;
}

.methodology {
  /* background: rgba(68, 229, 231, 0.6); */
  height: fit-content;
}

.carouselCard {
  width: max(calc(349px / 2), calc((5.8vw * 349) / 100));
  height: max(calc(362px / 2), calc((5.8vw * 362) / 100));

  background: #f5f5f5;
  border-radius: max(calc(60px / 2), calc((5.8vw * 60) / 100))
    max(calc(60px / 2), calc((5.8vw * 60) / 100))
    max(calc(55px / 2), calc((5.8vw * 55) / 100))
    max(calc(55px / 2), calc((5.8vw * 55) / 100));
  margin: auto;
  overflow: hidden;
}

.carouselCard::-webkit-scrollbar{
  display: none;
}

.carouselCard > h1 {
  height: max(calc(83px / 2), calc((5.8vw * 83) / 100));

  background: #ffc200;
  border-radius: max(calc(55px / 2), calc((5.8vw * 55) / 100))
    max(calc(55px / 2), calc((5.8vw * 55) / 100)) 0px 0px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(32px / 2), calc((5.8vw * 32) / 100));
  line-height: max(calc(36px / 2), calc((5.8vw * 36) / 100));
  /* or 112% */

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;

  color: #ffffff;
}

.carouselCard > p {
  height: max(calc(260px / 2), calc((5.8vw * 260) / 100));

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(20px / 2), calc((5.8vw * 20) / 100));
  line-height: max(calc(36px / 2), calc((5.8vw * 36) / 100));
  display: flex;
  justify-content: center;
  /* align-items: center; */
  align-content: center;
  text-align: center;

  color: #000000;
  overflow-y: scroll;
  margin: 0px;
  border-radius: 0px 0px max(calc(55px / 2), calc((5.8vw * 55) / 100))
    max(calc(55px / 2), calc((5.8vw * 55) / 100));
}

/* width */
.carouselCard > p::-webkit-scrollbar {
  width: 1px;
}

/* Track */
.carouselCard > p::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.carouselCard > p::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.carouselCard > p::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.methodologyCarousel {
  padding-bottom: 100px;
}
.servicePageBenefits {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 max(calc(60px / 2), calc((5.8vw * 60) / 100)) 0;
  /* background: #191970; */
  background: #003366;
}

.servicePageBenefits > h1 {
  margin-left: max(calc(60px / 2), calc((5.8vw * 60) / 100));
  font-family: "Piazzolla";
  font-style: italic;
  font-weight: 900;
  font-size: max(calc(60px / 2), calc((5.8vw * 96) / 100));
  line-height: max(calc(63px / 2), calc((5.8vw * 63) / 100));

  text-align: center;

  color: #000000;
}
.servicePageBenefits > h1 > span {
  width: max(calc(94px / 2), calc((5.8vw * 94) / 100));
  height: max(calc(108px / 2), calc((5.8vw * 108) / 100));

  font-family: "Piazzolla";
  font-style: italic;
  font-weight: 900;
  font-size: max(calc(128px / 2), calc((5.8vw * 128) / 100));
  line-height: max(calc(63px / 2), calc((5.8vw * 63) / 100));

  text-align: center;

  color: #ffffff;
}

.benefitsList {
  margin-bottom: max(calc(100px / 2), calc((5.8vw * 100) / 100));
}

.benefitsList > li {
  display: flex; /* Use flexbox to align items */
  align-items: center; /* Vertically center the items */
  text-align: left;
  /* margin-bottom: max(calc(20px / 2), calc((5.8vw * 20) / 100));  */
}

.benefitsList > li > p {
  /* font-family: "Pavanam"; */
  font-style: normal;
  font-weight: 400;
  font-size: max(calc(25px / 2), calc((5.8vw * 25) / 100));
  line-height: max(calc(43px / 2), calc((5.8vw * 43) / 100));
  margin: 0; /* Remove default margin */
  color: #ffffff;
  /* margin-bottom: 20px; */
}

.benefitsList > li > span {
  font-family: "Montez";
  font-style: normal;
  font-weight: 100;
  font-size: max(calc(48px / 2), calc((5.8vw * 48) / 100));
  line-height: max(calc(63px / 2), calc((5.8vw * 63) / 100));

  text-align: center;

  color: #000000;
}

.benefitsList > li > p::before {
  /* content: ""; */
  width: max(calc(7px / 2), calc((5.8vw * 7) / 100)); 
  height: max(calc(7px / 2), calc((5.8vw * 7) / 100)); 
  border-radius: 50%; 
  background: #ffffff;
  /* border: 2px solid #333;  */
  display: inline-flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  margin-right: max(calc(15px / 2), calc((5.8vw * 15) / 100)); 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}


.benefitsVideo {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  /* justify-content: center; */
  padding-block: max(calc(100px / 2), calc((5.8vw * 150) / 100));
}
.benefitsVideo > img {
  width: max(calc(500px / 2), calc((5.8vw * 500) / 100));
  height: max(calc(300px / 2), calc((5.8vw * 300) / 100));
  
  position: absolute;
  /* background: #ffc200; */
  /* border-radius: 30px; */
  padding: 5px;
}

.benefitsVideo > iframe {
  width: max(calc(455px / 2), calc((5.8vw * 455) / 100));
  height: max(calc(275px / 2), calc((5.8vw * 275) / 100));
}

.benefitsVideo > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px max(calc(16px / 2), calc((5.8vw * 16) / 100));
  gap: max(calc(10px / 2), calc((5.8vw * 10) / 100));

  position: relative;
  width: max(calc(204px / 2), calc((5.8vw * 204) / 100));
  height: max(calc(35px / 2), calc((5.8vw * 35) / 100));

  background: #d6e867;
  border-radius: max(calc(10px / 2), calc((5.8vw * 10) / 100));
  font-family: "Pavanam";
  font-style: normal;
  font-weight: 400;
  font-size: max(calc(24px / 2), calc((5.8vw * 24) / 100));
  line-height: max(calc(62px / 2), calc((5.8vw * 62) / 100));

  text-align: center;

  color: #630f0f;
  border: none;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: max(calc(50px / 2), calc((5.8vw * 50) / 100));
}

.benefitsVideo > button::after {
  content: "";
  background-image: url(../assets/icons/smallArrow.png);
  width: max(calc(17px / 2), calc((5.8vw * 17) / 100));
  height: max(calc(17px / 2), calc((5.8vw * 17) / 100));
  background-size: contain;
}

.caseStudyPage {
  padding-top: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #fece30;
  padding-bottom: 200px;
}
.caseStudyPage > img {
  width: 100%;
  max-height: 80vh;
  object-fit: cover;
}

.caseStudyPage > h1 {
  font-family: "Piazzolla";
  font-style: normal;
  font-weight: 600;
  font-size: max(calc(50px / 2), calc((5.8vw * 50) / 100));
  line-height: max(calc(45px / 2), calc((5.8vw * 45) / 100));
  /* identical to box height, or 206% */

  text-align: center;
  position: relative;
  color: #000000;
}

/* .caseStudyPage > h1::after {
  content: "";
  background: url(../assets/images/underline.svg);
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  display: block;
  width: max(calc(300px / 2), calc((5.8vw * 300) / 100));
  height: max(calc(8px / 2), calc((5.8vw * 8) / 100));
  margin: auto;
} */

.caseStudyPage > p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: max(calc(18px / 2), calc((5.8vw * 18) / 100));
  line-height: max(calc(30px / 2), calc((5.8vw * 30) / 100));
  /* or 172% */

  text-align: center;

  color: #1b1b1b;
  max-width: max(calc(729px / 2), calc((5.8vw * 729) / 100));
  text-align: center;
  margin: auto;
  margin-top: 22px;
}

.caseStudyPage > a {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  /* padding: 0px; */
  border-radius: max(calc(40px / 1.8), calc((5.8vw * 40) / 100));
  font-size: max(calc(48px / 2.2), calc((5.8vw * 48) / 100));
  font-weight: bold;
  color: #283248;
  width: max(calc(420px / 2.5), calc((5.8vw * 420) / 100));
  height: max(calc(100px / 2.5), calc((5.8vw * 100) / 100));
  border: max(calc(6px / 2.5), calc((5.8vw * 6) / 100)) solid #7452ff;
  border-bottom: max(calc(12px / 2.5), calc((5.8vw * 12) / 100)) solid #7452ff;
  background-color: white;
  cursor: pointer;
  text-decoration: none;
  margin-top: 80px;
}
.caseStudyPage > a:hover {
  background-color: #7452ff;
  color: white;
}
.caseStudyPage > i {
  /* align-self: flex-end; */
  margin-top: 20px;
}
/*  Start Contact */
.contactWrapper {
  padding: max(calc(40px / 1.8), calc((5.8vw * 40) / 100));
}
.contactSection {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);

  background-color: white;
  display: flex;
  margin: max(calc(50px / 1.8), calc((5.8vw * 50) / 100));
}

.contactSection > div:first-child {
  width: 100%;
  flex: 1 / 2;
  padding: 20px;
}

.contactSection > div:first-child > h1 {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(64px / 1.8), calc((5.8vw * 64) / 100));

  text-transform: uppercase;

  color: #0174cf;
}

.contactSection > div:nth-child(2) {
  width: 100%;
  flex: 1 / 2;
}

.contactInput,
.contactTextArea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 60px;

  margin-top: 10px;

  border: 1px solid #0174cf;
  font-family: Inter;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #7452ff;
  padding-left: 28px;
}
.contactInput::placeholder,
.contactTextArea::placeholder {
  font-family: Inter;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #7452ff;
}

.contactTextArea {
  height: 220px;
  padding: 28px;
  font-family: Inter;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #7452ff;
  padding-left: 28px;
}

.contactBtn {
  height: 62px;
  width: 235px;
  border: 0;
  color: white; /* Text color */
  border-radius: 0;
  background-color: #ffbb33; /* Yellow-orange */
  font-family: Inter;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: auto;
  float: right;
  margin-top: 20px;
  border: 2px solid #ffbb33; /* Match border to background */
  transition: all 0.5s;
  cursor: pointer;
}

.contactBtn:hover {
  color: #ffbb33; /* Change text color on hover */
  background-color: #ff9800; /* Darker orange for hover */
  transition: all 0.5s;
}

/*  End Contact */

.contactPageHero {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  height: 100vh;
}

.contactPageHero > img {
  width: max(calc(400px / 1.8), calc((5.8vw * 400) / 100));
}
.contactPageHero > div {
  text-align: center;
}
.contactPageHero > div > h1 {
  font-family: "Content";
  font-style: normal;
  font-weight: 400;
  font-size: max(calc(48px / 1.8), calc((5.8vw * 48) / 100));
  line-height: max(calc(62px / 1.8), calc((5.8vw * 62) / 100));
  /* margin-top: max(calc(50px / 1.8), calc((5.8vw * 50) / 100)); */
  display: flex;
  align-items: center;
  text-align: center;
  max-width: 1000px;
  color: #000000;
}
.contactPageHero > div > h2 {
  font-family: "Figma Hand";
  font-style: normal;
  font-weight: 400;
  font-size: max(calc(32px / 1.8), calc((5.8vw * 32) / 100));
  line-height: max(calc(42px / 1.8), calc((5.8vw * 42) / 100));

  /* display: flex;
  align-items: center;
  text-align: center; */

  color: #271033;
}

.contactPageHero > a,
.contactPageBottom > a {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  /* padding: 0px; */
  border-radius: max(calc(40px / 1.8), calc((5.8vw * 40) / 100));
  font-size: max(calc(48px / 1.8), calc((5.8vw * 48) / 100));
  font-weight: bold;
  color: #283248;
  width: max(calc(420px / 1.8), calc((5.8vw * 420) / 100));
  height: max(calc(100px / 1.8), calc((5.8vw * 100) / 100));
  border: 5px solid #7452ff;
  border-bottom: 10px solid #7452ff;
  background-color: white;
  cursor: pointer;
  text-decoration: none;
}
.contactPageHero > a:hover,
.contactPageBottom > a:hover {
  background-color: #7452ff;
  color: white;
}

.contactPhilo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 50px;
}
.contactPhilo > h1 {
  font-family: "Courier Prime";
  font-style: normal;
  font-weight: 400;
  font-size: max(calc(36px / 1.8), calc((5.8vw * 36) / 100));
  line-height: max(calc(47px / 1.8), calc((5.8vw * 47) / 100));

  text-align: center;

  color: #000000;
}

.contactPhilo > p {
  font-family: "Content";
  font-style: normal;
  font-weight: 700;
  font-size: max(calc(28px / 1.8), calc((5.8vw * 28) / 100));
  line-height: max(calc(36px / 1.8), calc((5.8vw * 36) / 100));

  text-align: center;

  color: #000000;
  max-width: 480px;
}

.contactPhilo > p:nth-child(2) > span {
  color: #fa8334;
}
.contactPhilo > p:nth-child(3) > span:nth-child(1),
.contactPhilo > p:nth-child(3) > span:nth-child(2) {
  color: #ffe882;
}
.contactPhilo > p:nth-child(3) > span:nth-child(3) {
  color: #fa8334;
}

.contactPageBottom {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  height: 110vh;
  padding-block: max(calc(80px / 1.8), calc((5.8vw * 80) / 100));
}
.contactPageBottom > h1 {
  font-family: "Content";
  font-style: normal;
  font-weight: 700;
  font-size: max(calc(48px / 1.8), calc((5.8vw * 48) / 100));
  line-height: max(calc(62px / 1.8), calc((5.8vw * 62) / 100));

  display: flex;
  align-items: center;
  text-align: center;

  color: rgba(250, 131, 52, 1);
}
.contactPageBottom > p {
  font-family: "Content";
  font-style: normal;
  font-weight: 700;
  font-size: max(calc(28px / 1.8), calc((5.8vw * 28) / 100));
  line-height: max(calc(36px / 1.8), calc((5.8vw * 36) / 100));
  max-width: 1000px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 600px) {
  .servicePage {
    margin-top: 100px;
  }

  .contactPage {
    margin-top: 100px;
  }
}
@media only screen and (max-width: 900px) {
  .homeAboutSection {
    margin-inline: 10px;
    /* padding: 10px; */
  }
  .welcomeBlock,
  .ourTeamBlock,
  .brksBlock {
    margin-bottom: 20px;
  }
  .homeHeroText {
    margin-top: 50px;
  }
  .steam > div:nth-child(1) > h1 {
    width: 100%;
  }

  .steam > div {
    padding: 50px;
  }
  .steam > div > div > div > img {
    width: 100%;
    margin-block: 50px;
  }
  .benefits {
    margin-top: 10px;
  }
  .benefits > div {
    padding: 20px;
    justify-content: space-around;
  }

  .benefits > div > img {
    max-width: 50px;
  }
  .plans > div {
    padding: 10px;
    width: 100%;
  }

  .plans > div:nth-child(1),
  .plans > div:nth-child(2) {
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  /* .servicePageTitleImages > img {
    max-width: 15%;
  } */
  .servicePagePoints > ul > span {
    display: none;
  }
  .contactWrapper {
    padding: 0px;
  }
  .contactPageHero {
    padding: 10px;
  }
  .contactPhilo {
    padding: 40px;
  }
  .contactPageBottom {
    padding: 20px;
  }
  .caseStudyPage {
    /* margin-top: 100px; */
    padding-top: 100px;
  }
}
